import React, {useState, useEffect} from 'react';
import CatoLink from '../cato-link';
import CatoImg from '../cato-image';
import {IS_MOBILE} from "../../common/consts";
import './style.scss';

const Wrap = ({children, path}) => path
    ?
    <CatoLink className="item-wrap" path={path}>{children}</CatoLink>
    :
    <div className="item-wrap">{children}</div>;

const ResourcesListItem = ({title, img, text, path, cta, type}) => {
    const [isMobile, setMobile] = useState(false);

    useEffect(() => {if(IS_MOBILE) setMobile(true)}, []);

    const genMiddle = () => {
        if(isMobile) {
            return (
                <Wrap path={path ? path : null}>
                    <div className="top">
                        <div className={`image-wrapper ${(type === 'webinars' || type === 'demos') ? 'video' : ''}`}>
                            {img && <CatoImg img={img}/>}
                        </div>
                        {title && <strong dangerouslySetInnerHTML={{__html: title}}/>}
                    </div>
                    <div className="bottom">
                        {text && <div className="text" dangerouslySetInnerHTML={{__html: text}} />}
                    </div>
                </Wrap>

            )
        }

        return (
            <Wrap path={path ? path : null}>
                <div className={`image-wrapper ${(type === 'webinars' || type === 'demos') ? 'video' : ''}`}>
                    {img && <CatoImg img={img}/>}
                </div>
                <div className="text-wrapper">
                    {title && <strong dangerouslySetInnerHTML={{__html: title}}/>}
                    {text && <div className="text" dangerouslySetInnerHTML={{__html: text}} />}
                    {cta && <span className="green">{cta}</span>}
                </div>
            </Wrap>
        )
    }

    return (
        <li className="resources-list-item">
            {genMiddle()}
        </li>
    )
}

export default ResourcesListItem;
