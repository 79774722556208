import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import './style.scss';


const PillFilters = ({items, active, handleClick, includeAll, theme}) => {

    const pillRef = useRef(null);
    const listRef = useRef(null);

    const movePill = (pos, width) => {
        pillRef.current.style.left = pos + 'px';
        pillRef.current.style.width = width + 'px';
    };

    const handleFilterClick = ({target}) => {

        handleClick(target.getAttribute('name'), target.dataset.value);
    };

    useEffect(() => {
        const activeLI = listRef.current.querySelector('.active');
        const menuItemPosition = activeLI.offsetLeft;
        const menuItemWidth = activeLI.offsetWidth;

        movePill(menuItemPosition, menuItemWidth);
    }, [active])

    return (
        <ul className={`pill-filters ${theme}`} ref={listRef}>
            {includeAll &&
                <li
                    className={active === 'all' ? 'active' : ''}
                    name="all"
                    onClick={handleFilterClick}
                >All</li>
            }
            {
                items && items.map((item, i) => (
                        <li key={`category-${i + 1}`}
                            name={item.displayName}
                            data-value={item.value}
                            className={active === item.displayName || active === item.value ? 'active' : ''}
                            onClick={handleFilterClick}
                        >
                            {item.displayName}
                        </li>
                    )
                )
            }
            <li className="pill" ref={pillRef} />
        </ul>
    )
};

PillFilters.propTypes = {
    theme: PropTypes.oneOf(['green', 'white'])
}

PillFilters.defaultProps = {
    includeAll: true,
    theme: 'white'
};

export default PillFilters;