import React, {useState} from 'react';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import './style.scss';


const DropdownFilter = ({items, label, active, handleSelection, cleanFilter}) => {

    const [open, toggleItemsList] = useState(false);

    const { isMobile } = useDeviceDetect();

    const handleItemSelection = ({target}) => {

        if(isMobile){
            toggleList();
        }

        handleSelection(target.getAttribute('name'));
    }

    const handleCleanFilter = (e) => {
        e.stopPropagation();
        // closeList();
        cleanFilter();
    };

    const toggleList = () => toggleItemsList(!open);

    const closeList = () =>  toggleItemsList(false);

    return (
        <div className="dropdown-filter"
             onClick={toggleList}
             // onMouseEnter={!isMobile ? toggleList : null}
             // onMouseLeave={!isMobile ? toggleList : null}
        >
            <div className={`display`}>
                <div className={`icon${active ? ' active' : ''}`} onClick={active ? handleCleanFilter : null}/>
                <span>{active ? active : label}</span>

            </div>
            <div className={`items${open ? ' show' : ''}`}>
                <ul>
                    {items.map((item, i) => (
                        <li
                            key={`dropdown-item-${i}`}
                            name={item.value}
                            onClick={handleItemSelection}
                        >
                            {item.displayName}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default DropdownFilter;