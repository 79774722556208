import React, {useState, useEffect, useContext} from 'react';
import {graphql} from "gatsby";
import Layout from '../components/layout';
import CatoButton from '../components/cato-button';
import PillFilters from '../components/pill-filters';
import ResourcesListItem from '../components/resources-list-item';
import DropdownFilter from '../components/dropdown-filter';
import {mapGartner} from '../common/utils';
import {IS_DESKTOP, RESOURCES_TYPE_TRANSLATE, LANG} from "../common/consts";
import NoResults from '../assets/images/library/no search results.svg';
import {GlobalStateContext} from "../context/GlobalContextProvider";
import "../assets/styles/pages/library.scss";
import "../assets/styles/responsive/pages/library-mobile.scss";

const LibraryPage = (props) => {

    const {data} = props;

    const {wpPage} = data;

    const {libraryResourcesTopicsParent} = wpPage;

    const lang = props.pageContext.lang;

    const resourceTypes = ['webinars', 'demos', 'whitePapers', 'eBooks'];

    const sections = resourceTypes.reduce((obj, key) => {
            obj[key] = {
                displayName: RESOURCES_TYPE_TRANSLATE[lang][key],
                items: data[key],
                display: 4
            };

            return obj;
        }, {});

    const sectionsDisplayNumber = {};

    const pageTitle = {
        [LANG.ENG]: "Cato Library",
        [LANG.JP]: "ケイト ライブラリー"
    };

    Object.keys(sections).forEach(item => sectionsDisplayNumber[item] = sections[item].display);

    const state = useContext(GlobalStateContext);

    const [category, setCategory] = useState('all');
    const [topic, setTopic] = useState(null);
    const [display, setDisplay] = useState(sectionsDisplayNumber);
    const [isDesktop, setDesktop] = useState(true);
    const [isOpen, toggleOpen] = useState([]);
    const [subheadingFixed, setSubheadingFixed] = useState();

    useEffect(() => {if(!IS_DESKTOP) setDesktop(false)},[]);

    useEffect(() => {
        if(state.headerFixed !== subheadingFixed) {
            setSubheadingFixed(state.headerFixed)
        }

    }, [state]);

    const handleCategoryFilterChange = (value) => {
        if(subheadingFixed) {
            window.scrollTo(0, 40);
        }
        setCategory(value);
    };

    const handleTopicChange = (value) => {
        if(subheadingFixed) {
            window.scrollTo(0, 35);
        }
        setTopic(value);
    }

    const clearTopicFilter = () => setTopic(null);

    const handleLoad = ({name}) => {
       const newDisplayNumber = display[name] + 5;

       const newDisplay = {
           ...display,
           [name]: newDisplayNumber
       }
        setDisplay(newDisplay);

    };

    const toggleMobileCategoryOpen = (section) => {
        const currOpen = [...isOpen];
        if(currOpen.includes(section)){
            const newOpen = currOpen.filter(item => item !== section);
            toggleOpen(newOpen);
        } else {
            toggleOpen([...currOpen, section])
        }
    }

    const topics = libraryResourcesTopicsParent.libraryResourcesTopics ? libraryResourcesTopicsParent.libraryResourcesTopics.map(item => ({displayName: item.topic, value: item.topic})) : null;

    /**
     * Generate list items in each category
     * @param section
     * @return {Array}
     */
    const genListItems = (section) => {
        return (
            sections[section].items.edges
                .filter(item =>
                    (!topic) || (item.node.resourceFields.resourceTopicChoice
                    && item.node.resourceFields.resourceTopicChoice.length
                    && item.node.resourceFields.resourceTopicChoice.includes(topic) ))
                .slice(0, display[section])
                .map((item, j) => (
                    <ResourcesListItem
                        key={`section-${section}-item-${j}`}
                        path={item.node.resourceFields.resourceLink}
                        img={item.node.resourceFields.resourceSmallImage}
                        type={section}
                        title={item.node.title}
                        cta={item.node.resourceFields.resourceButtonText}
                        text={item.node.resourceFields.resourceLibraryDescription}
                    />
                ))
        )
    }


    /**
     * Generate category sections
     * @return {Array}
     */
    const genCategories = () => {

        const categories = Object.keys(sections).filter(item =>
            (!topic || sections[item].items.edges.filter(item => item.node.resourceFields.resourceTopicChoice
                && item.node.resourceFields.resourceTopicChoice.length
                && item.node.resourceFields.resourceTopicChoice.includes(topic)).length > 0)
            &&
            (category === sections[item].displayName || category === 'all')
            &&
            sections[item].items.edges.length > 0);

        if (!categories.length) {
            return (
                <div className="no-results">
                    <img src={NoResults} />
                    <div>
                        <h2>No Results</h2>
                        <p>How about another try</p>
                    </div>
                </div>
            )
        }

        return categories.map( (section,i) => (
            <div key={`section-${i}`} className={`${!isDesktop && isOpen.includes(section) ? 'open' : ''}`}>
                <div className={`title ${!isDesktop && isOpen.includes(section) ? 'open' : ''}`} onClick={!isDesktop ? () => toggleMobileCategoryOpen(section) : null}>
                    {!isDesktop &&
                    <div className="icon" />
                    }
                    <h2>
                        {sections[section].displayName}
                    </h2>
                </div>
                <ul className={`items ${!isDesktop && isOpen.includes(section) ? 'open' : ''}`}>
                    {
                        genListItems(section)
                    }
                </ul>
                {
                    sections[section].items.edges.length > display[section] &&
                    ((!topic) || sections[section].items.edges.filter(item => item.node.resourceFields.resourceTopicChoice && item.node.resourceFields.resourceTopicChoice === topic).length > display[section]) &&
                    <CatoButton
                        name={section}
                        onClick={handleLoad}
                        className="orange oval library mid"
                        type="button"
                        label={`Load More ${sections[section].displayName}`}
                    />
                }
            </div>
        ))
    }

    const gartner = mapGartner(wpPage);

    // const pillFilters = Object.keys(sections).map(item => {
    //     if(sections[item].items.edges.length) {
    //         return sections[item];
    //     }
    // }).filter(item => item !== undefined);
    const pillFilters = Object.keys(sections).map(item => sections[item]);

    return (
        <Layout
            className="library-page"
            lang={props.pageContext.lang}
            breadcrumbs={[{title: wpPage.title, path: props.location.pathname}]}
            seoTitle={wpPage.pageSeoTitle.pageSeotitle}
            title={wpPage.title}
            yoastData={wpPage.seo || null}
            gartner={gartner}
            translations={props.pageContext.translations}
            seoPath={props.location.pathname}
            wpid={wpPage.databaseId}

        >
            <section className={`title-and-filters ${subheadingFixed ? 'fixed' : ''}`}>
                <div className="text-and-filters-wrapper">
                    <h1>{pageTitle[props.pageContext.lang] || pageTitle[LANG.ENG]}</h1>
                    <div className="filters" style={topics ? null : {justifyContent: 'center'}}>
                        {isDesktop && pillFilters.length &&
                            <div className="categories">
                                <PillFilters
                                    items={pillFilters}
                                    active={category}
                                    handleClick={handleCategoryFilterChange}
                                />
                            </div>
                        }
                        { topics &&
                            <div className="topics">
                                <DropdownFilter
                                    items={topics}
                                    label={'Filter by topic'}
                                    active={topic}
                                    handleSelection={handleTopicChange}
                                    cleanFilter={clearTopicFilter}
                                />
                            </div>
                        }
                    </div>
                </div>
            </section>
            <section className={`categories ${subheadingFixed ? 'hero-fixed' : ''}`}>
                {genCategories()}
            </section>
        </Layout>
    )
}

export default LibraryPage;

export const pageQuery = graphql`
    fragment ResourcesFields on WpResource {
        title
        id
        resourceFields {
            resourceLink
            resourceType
            resourceButtonText
            resourceLibraryDescription
            resourceTopicChoice
            resourceIncludeInLibrary
            resourceSmallImage {
                altText
                localFile {
                   publicURL
                }
            }
        }
    }
    query libraryPageQuery($id: String!, $lang: WpLanguageCodeEnum!) {
        wpPage(id: {eq: $id}) {
            ...YoastData
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle {
                pageSeoTitle
            }
            libraryResourcesTopicsParent {
                libraryResourcesTopics {
                    topic
                }
            }
            ...GartnerStrip
        }
    webinars: allWpResource(
        filter:
            {resourceFields:
                {
                    resourceType: {in: ["Webinar", "Video", "Webinar On Demand"]},
                    resourceIncludeInLibrary: { eq: true }
                },
             language: { code: {eq: $lang}}
            },
        sort: {order: DESC, fields: date}
        )
    {
        edges {
            node {
            ...ResourcesFields
            }
        }
    }
    caseStudies: allWpResource(
        filter:
            {resourceFields:
                {
                    resourceType: {in: ["Case Studies"]},
                    resourceIncludeInLibrary: { eq: true }
                },
            language: { code: {eq: $lang}}
            },
        sort: {order: DESC, fields: date}
        )
    {
        edges {
            node {
            ...ResourcesFields
            }
        }
    }
    whitePapers: allWpResource(
        filter:
            {resourceFields:
                {
                    resourceType: {in: ["White Paper"]},
                    resourceIncludeInLibrary: { eq: true }
                },
            language: { code: {eq: $lang}}
            },
        sort: {order: DESC, fields: date}
        )
    {
        edges {
            node {
            ...ResourcesFields
            }
        }
    }
    eBooks: allWpResource(filter: {resourceFields: {resourceType: {in: ["eBook"]}, resourceIncludeInLibrary: { eq: true }}, language: { code: {eq: $lang}}}) {
        edges {
            node {
            ...ResourcesFields
            }
        }
    }
    demos: allWpResource(
        filter:
            {resourceFields:
                {
                    resourceType: {in: ["Demo"]},
                    resourceIncludeInLibrary: { eq: true }
                },
            language: { code: {eq: $lang}}
            },
        sort: {order: DESC, fields: date}
        )
    {
        edges {
            node {
            ...ResourcesFields
            }
        }
    }
  }
 `;